<template>
	<div class="marketingLottery">
		<!-- 九宫格抽奖列表 -->
		<div class="el-content mb12">
			<router-link to="/marketing/lottery/edit">
				<a-button v-has="{action:'/marketing/lottery/edit'}" type="primary">新增抽奖</a-button>
			</router-link>
			<a href="https://www.yuque.com/docs/share/da555b49-bed5-431b-acbd-478ddac04aae?#" target="_blank" style="text-decoration: none;color: #177CE3;font-size: 13px;">《九宫格说明》</a>
		</div>
		<div class="el-content">
			<a-table :pagination="false" row-key="id" :data-source="info.list" :columns="[
				{title:'ID',dataIndex:'id'},
				{title:'开始时间',dataIndex:'start_time'},
				{title:'结束时间',dataIndex:'end_time'},
				{title:'抽奖类型',dataIndex:'type',slots:{customRender:'type'}},
				{title:'是否默认',dataIndex:'is_default',slots:{customRender:'is_default'}},
				{title:'抽奖次数',dataIndex:'frequency'},
				{title:'操作',dataIndex:'action',slots:{customRender:'action'}},
			]" >
				<template #name="{record}">
					<a-tag color="#33CCCC">{{record.type == 2 ?'每天抽奖总数':'总共抽奖次数'}}</a-tag>
				</template>
				<template #is_default="{record}">
					<a-tag :color="record.is_default == 1 ?'#33CCCC':'#999'">{{record.is_default == 1 ?'是':'否'}}</a-tag>
				</template>
				<template #action="{record}">
					<a-space>
						<router-link :to="{path:'/marketing/lottery/edit',query:{id:record.id}}">
							<kd-button type="primary" title="编辑" icon="ri-edit-line" v-has="{action:'/marketing/lottery/edit'}"></kd-button>
						</router-link>
						<kd-button type="danger" title="删除" icon="ri-delete-bin-5-line" @click="deleteLottery([record.id])" v-has="{action:'wxapp_marketing_lottery_del'}"></kd-button>
					</a-space>
				</template>
			</a-table>
			<div class="pager">
				<a-pagination
					show-size-changer
					:default-current="info.page"
					:total="info.count"
					@showSizeChange="(p,e)=>{getLotteryList(info.page,e)}"
					@change="(e)=>{getLotteryList(e,info.limit)}"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import { reactive, toRefs } from 'vue'
import lotteryModel from '@/api/addons/lottery'
import commonModel from '@/api/common'
export default{
	setup(){
		const state = reactive({
			info:{
				list:[],
				page:1,
				count:0,
				limit:10
			}
		})
		getLotteryList(1,state.info.limit)
		function getLotteryList(page,limit){
			lotteryModel.getLottery(page,limit,res=>state.info = {limit,...res})
		}
		const deleteLottery = (id)=>commonModel.deleteDataList(id,42,"确认删除该九宫格抽奖信息吗？",()=>{
			getLotteryList(state.info.page,state.info.limit)
		})
		return{
			...toRefs(state),
			getLotteryList,
			deleteLottery
		}
	}
}
</script>

<style>
</style>
